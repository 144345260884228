<template>
  <footer>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-none d-lg-block">
        <div class="d-flex align-items-center">
          <div>
            <font-awesome-icon
              v-if="enableAvatar"
              icon="user-circle"
              size="2xl"
              class="user-icon"
            />

            <div
              v-else
              class="network-indicator"
              :class="{ offline: !hasNetwork }"
            />
          </div>

          <div class="account-details">
            <div class="account-user">{{ accountUser }}</div>
            <div class="account-info">{{ accountInfo }}</div>
          </div>
        </div>
      </div>
      <div class="status-bar d-flex align-items-center right-content">
        <m-sync-status
          class="mr-2"
          :hasNetwork="hasNetwork"
          v-if="!isOfflineMode && !useSqliteOffloadReceipt"
        />

        <m-toggle-switch
          v-if=false
          :is-checked="!isOfflineMode"
          flip
          unique-id="offline-toggle"
          @toggle="toggleOfflineMode"
        />

        <div class="terminal mr-4">Terminal: {{ terminal }}</div>

        <m-pos-status />

        <div class="d-none d-lg-block datetime">{{ dateTime }}</div>

        <div class="logout" @click="goToHomePage">
          <font-awesome-icon icon="lock" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MPosStatus from "@/spa/components/common/MPosStatus";
import MSyncStatus from "@/spa/components/common/MSyncStatus";
import MToggleSwitch from "@/spa/components/common/MToggleSwitch";
import {ENABLE_AVATAR, ENABLE_TOGGLE_ONLINE_OFFLINE, OFFLOAD} from "@/spa/constants";
import { checkCloudConnection } from "@/spa/plugins/axios";
import { cashierLogout } from "@/spa/services/cashier-service";
import { clearStorage } from '@/spa/plugins/vuex';
import {mapActions, mapState} from "vuex";
import bus from "@/spa/utils/bus";

library.add(faUserCircle, faLock);

export default {
  name: "BottomContent",

  components: { MPosStatus, MSyncStatus, MToggleSwitch },

  data() {
    return {
      accountInfo: `Mosaic POS Ver. ${tempPosVersion} | ${tempAccountName} / ${tempAccountLocation}`,
      dateTime: moment().format("MMMM DD, YYYY | hh:mm:ss A"),
      enableAvatar: ENABLE_AVATAR,
      hasNetwork: true,
      isOfflineMode: true,
      enableToggleSwitch: ENABLE_TOGGLE_ONLINE_OFFLINE,
      useSqliteOffloadReceipt: OFFLOAD.sqliteOffloadReceipt,
    };
  },

  computed: {
    ...mapState('settings', ['posDate', 'currentShift']),

    accountUser() {
      if (OFFLOAD.sqliteOffloadPOSFE1328) {
        return this.currentShift.user_name;
      }

      return tempAccountCurrentUsername;
    },

    terminal() {
      const terminalJson = sessionStorage.getItem('terminal');

      // terminal_id may be empty--fall back to other sources if needed.
      if (OFFLOAD.sqliteOffloadPOSFE1328 && this.currentShift.terminal_id) {
        return this.currentShift.terminal_id;
      } else if (terminalJson) {
        const terminal = JSON.parse(terminalJson);

        return terminal.id;
      }

      return tempTerminalId;
    }
  },

  methods: {
    ...mapActions(['forceSyncOrders']),

    async goToHomePage() {
      if (window.enableOfflineMode) {
          this.$swal.warning('Please switch to online mode to proceed with logging out.');
          return;
      }

      if (!await checkCloudConnection()) {
          this.$swal.warning('Kindly check your internet connection to proceed with logging out.');
          return;
      }

      try {
        await this.forceSyncOrders(this.posDate);

        this.$store.commit('user/resetState');
        this.$store.commit('global/resetState');
        this.$store.commit('modals/resetState');
        this.$store.commit('settings/resetState');
        this.$store.commit('resetState');
        clearStorage();
        await cashierLogout()

        window.location.href = "/cashier/home";
      } catch (error) {
        const errorMessage = error?.response?.data?.message ?? 'Something went wrong';
        this.$swal.error(errorMessage);
      }
    },

    toggleOfflineMode(value, swalFire = true) {
      const setItem = () => {
        this.isOfflineMode = value;
        window.enableOfflineMode = value;
        bus.emit("watchToggleOfflineMode", value);
        console.log('enableOfflineMode', window.enableOfflineMode);
      }

      if (!swalFire) {
        setItem();
        return;
      }

      const text = value
        ? 'Note: Please note that clearing cache is not recommended while in offline mode as it will result in the deletion of all your transactions. Make sure you have no pending transactions before proceeding.'
        : '';
      Swal.fire({
        title: `Are you sure you want to switch to ${value ? 'offline' : 'online'} mode?`,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1b75bb',
        cancelButtonColor: '#f47070',
        confirmButtonText: `Yes, go ${value ? 'offline' : 'online'}!`,
        customClass: {
          actions: 'void-swal-action',
        }
      })
      .then((result) => {
          if (result.isConfirmed) setItem();
      });
    }

  },

  mounted() {
    setInterval(
      () => (this.dateTime = moment().format("MMMM DD, YYYY | hh:mm:ss A")),
      1000
    );

    window.addEventListener("offline", () => (this.hasNetwork = false));
    window.addEventListener("online", () => (this.hasNetwork = true));
    if(!window.isOnline){
      this.toggleOfflineMode(true, false);
    }else{
      this.toggleOfflineMode(false, false);
    }
  },

  beforeDestroy() {
    window.removeEventListener("offline", () => (this.hasNetwork = false));
    window.removeEventListener("online", () => (this.hasNetwork = true));
  },
};
</script>

<style scoped>
footer {
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #002340;
  color: white;
  padding: 5px 10px 5px 75px;
  left: 0;
  z-index: 100;
}

.user-icon {
  color: #58cbef;
}

.account-user {
  font-weight: 600;
  line-height: 1rem;
}

.account-info {
  font-size: 0.7rem;
}

.terminal,
.datetime {
  text-transform: uppercase;
  font-weight: 600;
}

.datetime {
  font-family: sans-serif;
  line-height: 0;
}

.logout {
  padding: 10px;
  cursor: pointer;
}

.logout:hover {
  color: red;
}

.network-indicator {
  background-color: #51dc6b;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
}

.network-indicator.offline {
  background-color: red;
}
</style>