import { DataOffloadBridge } from './offload';

export class GenerateReportBridge extends DataOffloadBridge {
    constructor() {
        super('GenerateReport');
    }

    async getGeneratedReport() {
        return await this.sendMessage('GENERATE_REPORT');
    }
}