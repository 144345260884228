// BRIDGES FOR REPORT & SALES FILE RELATED DATA

import { DataOffloadBridge } from './offload';

export class ReportDataBridge extends DataOffloadBridge {
  constructor() {
    super('ReportData');
  }
}

export class AccreditationBridge extends DataOffloadBridge {
  constructor() {
    super('Accreditation');
  }
}

export class AccreditationTypeBridge extends DataOffloadBridge {
  constructor() {
    super('AccreditationType');
  }
}

export class TaxDetailBridge extends DataOffloadBridge {
  constructor() {
    super('TaxDetail');
  }
}

export class TaxLocationBridge extends DataOffloadBridge {
  constructor() {
    super('TaxLocation');
  }
}

export class TaxTypeBridge extends DataOffloadBridge {
  constructor() {
    super('TaxType');
  }
}

export class ShiftChangeBridge extends DataOffloadBridge {
  constructor() {
    super('ShiftChange');
  }

  async getShiftChangeById(id) {
    return await this.sendMessage('GET_SHIFT_CHANGE_BY_ID', { id });
  }

  async getShiftChangesByDate(businessDate) {
    return await this.sendMessage('GET_SHIFT_CHANGES_BY_DATE', { businessDate });
  }

  async insertShiftChange(shiftChange) {
    return await this.sendMessage('INSERT_SHIFT_CHANGE', { shiftChange });
  }

  async deleteShiftChange(id) {
    return await this.sendMessage('DELETE_SHIFT_CHANGE', { id });
  }
}

export class LocationPosCashBridge extends DataOffloadBridge {
  constructor() {
    super('LocationPosCash');
  }

  async getByLocationId(locationId) {
    return await this.sendMessage('GET_BY_LOCATION_ID', { locationId });
  }

  async upsert(locationPosCash) {
    return await this.sendMessage('UPSERT', { locationPosCash });
  }
}
