// Import dependencies
import axios from "axios";
import '../main.css';
import axiosRetry from 'axios-retry';
import localForage from "localforage";
import { setupCache } from "axios-cache-adapter";
import { toNumbersDeep } from '../store/orders';
import vuexStore, { clearStorage } from '@/spa/plugins/vuex';
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {OFFLOAD} from "@/spa/constants";

export const store = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: "mosaic-pos-spa-axios",
});

export const clearAxiosCache = () => store.clear();

const TIMEOUT = 30000;
const HEALTH_CHECK_TIMEOUT = 10000;
const HEALTH_CHECK_SLEEP = 5000;
const MAX_RETRIES = 5;
const MAX_SQLITE_REQUEST = 5;
let requestCounter  = 0;
let sqliteRequestCounter  = 0;
let healthCheckFailed = false;

const incrementRequestCounter = (config) => {
    if (config.url == route("orders.broadcast.offline")) {
        requestCounter++;
    }
    if (config.url.startsWith('/sync-offload-orders')) {
        sqliteRequestCounter++;
    }
};

const decrementRequestCounter = (config) => {
    if (config.url == route("orders.broadcast.offline")) {
        requestCounter--;
    }
    if (config.url.startsWith('/sync-offload-orders')) {
        sqliteRequestCounter--;
    }
};

export const hasPendingRequests = () => requestCounter > 0;
export const isSqliteRequestLimitNotReached = () => sqliteRequestCounter <= MAX_SQLITE_REQUEST;

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 60 * 60 * 1000, // 1 hr
  exclude: {
    query: false,
  },
  store,
  key: ({ url }) => `${window.userId}-${url}`,
  invalidate: async (config, request) => {
    if (request.forceRefresh) {
      await config.store.removeItem(config.uuid)
    }
  },
});

const api = axios.create({
  adapter: cache.adapter,
  timeout: TIMEOUT,
});

axiosRetry(api, {
  retries: MAX_RETRIES,
  retryDelay: axiosRetry.exponentialDelay
});

const responseHandler = response => {
    if (response.status === 401) {
      window.location.href = `/spa/${window.locationId}/${window.userId}`;
    }

    return {
      ...response,
      data: toNumbersDeep(response.data),
    };
};

const errorHandler = async error => {
    if (error.response?.status === 401) {
      await window.Swal.fire({
        title: 'Session Expired',
        text: 'Your session has expired. Please login again.',
      });

      vuexStore.commit('user/resetState');
      vuexStore.commit('global/resetState');
      vuexStore.commit('modals/resetState');
      vuexStore.commit('settings/resetState');
      vuexStore.commit('resetState');
      sessionStorage.clear();
      localStorage.clear();
      await clearStorage();
      await clearAxiosCache();
      window.location.href = '/';
    }

    return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => {
    decrementRequestCounter(response.config);
    return responseHandler(response)
  },
  (error) => {
    decrementRequestCounter(error.config);
    return errorHandler(error)
  },
);

export async function checkCloudConnection(skip = true) {
  if (healthCheckFailed || window.enableOfflineMode
      || (skip && OFFLOAD.sqliteOffloadReceipt && !isNetworkStable())) {
    return false;
  }

  try {
    await axios.head(route('health.check'), {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      timeout: HEALTH_CHECK_TIMEOUT,
    });
    return true;
  } catch (e) {
    healthCheckFailed = true;
    setTimeout(() => healthCheckFailed = false, HEALTH_CHECK_SLEEP);

    return false;
  }
}

async function createAuthSession(username, password) {
  return axios.post(route('initial.auth.login'), { username, password });
}

api.interceptors.request.use(async config => {
  incrementRequestCounter(config);

  if (window.enableOfflineMode) {
    console.log("API calls are blocked because you are offline", config.url);

    await window.Swal.fire({
      title: 'You’re in Offline Mode',
      html: `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M50 7.5332C50.6229 7.5332 51.2327 7.53529 51.8294 7.53966C60.4054 7.6007 67.5746 10.6453 72.7317 15.813C77.7319 20.822 80.7806 27.769 81.4427 35.7724C92.0094 39.4376 99 48.6278 99 60.8886C99 68.6722 96.1819 75.2447 91.3733 79.9463C86.5692 84.6438 79.84 87.414 72.1044 87.7211C66.1808 87.9563 58.9006 88.1109 50 88.1109C41.0994 88.1109 33.8192 87.9563 27.8956 87.7188C20.16 87.414 13.4308 84.6417 8.62667 79.9463C3.81813 75.2447 1 68.6722 1 60.8886C1 48.6257 7.99063 39.4376 18.5573 35.7724C19.2194 27.767 22.2681 20.822 27.2683 15.813C32.4254 10.6451 39.5946 7.6007 48.1706 7.53966C48.7673 7.53529 49.3771 7.5332 50 7.5332Z" fill="#343A40"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.22748 4.77191C6.35373 2.64504 9.80186 2.64504 11.9281 4.77191L94.6837 87.5275C97.6477 90.4915 96.2914 95.5525 92.2427 96.6373C90.3635 97.1409 88.3585 96.6036 86.9831 95.2282L4.22748 12.4725C2.10061 10.3463 2.10061 6.89816 4.22748 4.77191Z" fill="#343A40"/>
            </svg>      
            <p style="font-size: 1rem; color: #6b7280; margin-top: 10px;">
              You’re currently in offline mode. Data won’t sync while you’re offline. 
              To sync your data, please end your shift and reconnect to the internet.
            </p>`,
      imageWidth: 80,
      imageHeight: 80,
      imageAlt: 'Offline Icon',
      background: '#ffffff',
      color: '#1f2937',
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
      customClass: {
        popup: 'offline-mode-popup-class',
      },
    });
    

    decrementRequestCounter(config);

    return Promise.reject(new axios.Cancel('Offline mode: API call blocked'));
  }

  const hasNoAuth = await localForage.getItem('hasNoAuth');
  if (!hasNoAuth) return config;

  const isConnected = await checkCloudConnection();
  if (!isConnected) return config;

  if (await localForage.getItem('isCreatingOnlineSession')) return config;

  console.info('Attempting to create online auth session');
  await localForage.setItem('isCreatingOnlineSession', true);
  const currentUsername = await localForage.getItem('offlineUsername');
  const password = await localForage.getItem(`${currentUsername}-pw`);
  try {
    await createAuthSession(currentUsername, password);
    await localForage.setItem('hasNoAuth', false);
    console.info('Online auth session established');
  } catch (error) {
    console.error('Failed to create online auth session, error:');
    console.error({ error });
  }

  return config;
});

export default api;
