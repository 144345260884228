import axios from "@/spa/plugins/axios";
import {OFFLOAD} from "@/spa/constants";
import { GenerateReportBridge } from "../../mobile_bridge/offload/generate_report";

export const saveDayendData = (data) => {
    return axios.post(route('save.day.end.terminal'), data);
};

export const recordDayend = async (data) => {
    const ayalaAccrediation = false;
    if (USE_RECEIPT_BASED) {
        return await axios.post(window.ROUTE_GENERATE_REPORT, {
            ...data,
            xRead: false,
            posDate: data.pos_date,
            dayEndData: data.shift_data,
            shiftData: data.shift_data,
            isPreview: data.is_preview,
            isReprint: data.is_reprint,
            terminalId: data.terminal_id,
            useSqliteOffload: data.use_sqlite_offload,
            useNetSalesWCharges: OFFLOAD.useNetSalesWCharges,
            useOriginalGrossSales: OFFLOAD.useOriginalGrossSales,
        });
    }

    if(ayalaAccrediation) {
        const generateReportBridge = new GenerateReportBridge();
        const data = await generateReportBridge.getGeneratedReport();
        return data;
    }

    return await axios.post(route('print.z.report'), data);
};

export const recordShiftChange = data => {
    return axios.post(route('record.shift.change'), data);
};

export const getReprintDataReceiptBased = async (payload) => {
    const ayalaAccrediation = false;
    const routeUrl = window.ROUTE_GENERATE_REPORT;

    const payLoad = {
        dayEndData: {},
        locationId: '',
        terminalId: 1,
        posDate: sessionStorage.getItem('posDate'),
        zReadDate: sessionStorage.getItem('posDate'),
        shiftData: {},
        isReprint: 1,
        isPreview: 0,
        isRawData: 1,
        xRead: 0,
        ...payload,
        useNetSalesWCharges: OFFLOAD.useNetSalesWCharges,
        useOriginalGrossSales: OFFLOAD.useOriginalGrossSales,
    };

    if(ayalaAccrediation) {
        const generateReportBridge = new GenerateReportBridge();
        const data = await generateReportBridge.getGeneratedReport();
        return data;
    }

    return axios.post(routeUrl, payLoad);
}

export const getReprintData = zReadDate => {
    return axios.post(route('print.z.report'), {
        z_read_date: zReadDate,
        is_reprint: true,
        raw_data: true,
    });
}

export const generateTxtFile = (location, business_date) => {
    return axios.get(
        route("generate.text.file", { location, business_date }), {
            responseType: "blob"
        }
    )
}

export const getProductMixDataReceiptBased = async payload => {
    const routeUrl = window.ROUTE_GENERATE_PRODUCT_MIX_REPORT;

    const payLoad = {
        locationId: '',
        terminalId: 1,
        posDate: sessionStorage.getItem('posDate'),
        isPreview: 0,
        isRawData: 1,
        ...payload,
    };

    return axios.post(routeUrl, payLoad);
}

export const getProductMixData = filterDate => {
    return axios.post(route('print.product_mix.report'), {
        filter_date: filterDate,
        raw_data: true,
    });
}

export default {
    saveDayendData,
    recordDayend,
    recordShiftChange,
};
