import axios from "@/spa/plugins/axios";
import originalAxios from 'axios';
import {OFFLOAD} from "@/spa/constants";
import {getSqliteApprovers} from "@/mobile_bridge/offload/discount";

export const getOwnBrands = (skip = true) => {
  if (skip && OFFLOAD.sqliteOffloadProduct) return;
  return axios.get(route("get.brands.own"));
};

export const getChannels = (skip = true) => {
  if (skip && OFFLOAD.sqliteOffloadProduct) return;
  return axios.get(route("get.channels"), {
    cache: { maxAge: 0 },
  });
};

export const getLastKot = () => {
  return axios.get(route("get.last.kot"), { cache: { maxAge: 0 } });
};

export const getFreshLastKot = () => {
  return originalAxios.get(route("get.last.kot"));
};

export const getReceiptDetails = async () => {
  const rdb = new ReceiptDetailBridge();
  if (OFFLOAD.sqliteOffloadMA68) {
    const receiptDetails = await rdb.getAll();
    if (receiptDetails.data.length > 0) {
      return receiptDetails;
    }
  }
  const {data} = await axios.get(route("get.receipt.details"));

  await rdb.bulkImport(data);
  return {
    data
  };
};

export const getApprovers = async () => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    return {
      data: await getSqliteApprovers()
    }
  }

  return axios.get(route("get.approvers"), { cache: { maxAge: 1000 } });
};

export const getPaymentMethods = async () => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    const pmb = new PaymentMethodBridge();
    return {
      data: await pmb.getAll()
    };
  }

  return axios.get(route("get.payment.methods"));
};

export const getEPayments = () => {
  return axios.get(route("get.epayments"), { cache: { maxAge: 0 } });
};

export const getPaymentInvoices = (params) => {
  if (window.enableOfflineMode) return Promise.resolve({ data: [] });

  return axios.get(route("invoices.index", {
    ...params,
    paramsSerializer: (currentParams) => {
      return qs.stringify(currentParams);
    },
  }), { cache: { maxAge: 0 } });
}

export const createPaymentInvoice = (params) => {
  return axios.post(route("invoices.store"), {
    ...params
  });
};

export const cancelPaymentInvoice = (externalId) => {
  return axios.post(route("invoice.cancel", externalId));
};

export const getVoidReasons = async () => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    const crb = new CancellationReasonBridge();
    const {rows} = await crb.getAll();
    return {
      data: rows
    }
  }

  return axios.get(route("get.void.reasons"));
};

export const getCancellationReasonsByComp = () => {
  return axios.get(
    route("get.cancellation.reason", {
      name: "Comp",
    })
  );
};

export const getCancellationRatesByComp = () => {
  return axios.get(
    route("get.cancellation.rates", {
      name: "Comp",
    })
  );
};

export const getCashFloat = (terminal_id = null) => {
  return axios.get(route("get.cash_float",[terminal_id]), { cache: { maxAge: 100 } });
};

export const saveCashFloat = (cashFloat, terminalId) => {
  return axios.post(route("add.cash.fund"), {
    cash_fund: cashFloat,
    terminal_id: terminalId,
  });
}

export const getBillsDenomination = () => {
  return axios.get(route("bills.denomination"));
};

export const getShiftTable = (user_id = null) => {
  return axios.get(route("get.shift_table"), { params: {user_id}, cache: { maxAge: 1000 } });
};

export const getPosDate = () => {
  return axios.get(route("get.pos_date"), { cache: { maxAge: 1000 } });
};

export const getXreadDetails = () => {
  return axios.get(route("get.get_xread_details"), { cache: { maxAge: 1000 } });
};

export const logout = () => {
  return axios.post(route("logout"));
};

export const cashierLogout = () => {
  return axios.post(route("cashier.logout"));
};

export const getLoggedInUsers = () => {
  return axios.post(route("cashier.get.all"));
};

export const getTerminals = () => {
  return axios.get(route("cashier.terminal"),{ cache: { maxAge: 1000 } });
};

export const getFloatActivities = (terminalId) => {
  return axios.get(route("float.activities", {terminalId}),{ cache: { maxAge: 1 } });
};

export const getFloatReasons = () => {
  return axios.get(route("float.reasons"),{ cache: { maxAge: 1000 } });
};

export const addFloatTransaction = (data) => {
  return axios.post(route("floats.store"), data);
}

export const addFloatTransactionOld = (data) => {
  return axios.post(route("floats.store.old"), data);
}

export const updateCashFloat = (id, data) => {
  return axios.put(route("floats.update", id), data);
}

export const deleteCashFloat = (id) => {
  return axios.delete(route("floats.destroy", id));
}

export const updateOrCreateAttendance = (data) => {
    return axios.post(route("cashier.post.attendance"), data)
}

export const getLatestOrderId = () => {
  if (window.enableOfflineMode) return Promise.resolve({ data: {} });

  return originalAxios.get(route('orders.latest.id'));
}

export default {
  getOwnBrands,
  getChannels,
  getLastKot,
  getFreshLastKot,
  getReceiptDetails,
  getApprovers,
  getPaymentMethods,
  getVoidReasons,
  getCancellationReasonsByComp,
  getCancellationRatesByComp,
  getCashFloat,
  getBillsDenomination,
  getShiftTable,
  getPosDate,
  getXreadDetails,
  saveCashFloat,
  getFloatReasons,
  getLatestOrderId,
};
